.carousel-small-white-pagination {
    .swiper-wrapper {
        padding-bottom: 2rem;
    }
    .swiper-pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.3rem;
        .swiper-pagination-bullet {
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            cursor: pointer;
            background-color: #aaa;
            &.swiper-pagination-bullet-active {
                background-color: #fff;
            }
        }
    }
}
