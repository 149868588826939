:root {
    --new-primary: #514bee;
    --new-success: #3dbd41;
    --new-info: #2196f3;
    --new-error: #e62b1e;
    --new-warning: #ffc107;
    --new-pastel-gold: #9c7808;
    --new-pastel-cyan: #00e1d7;
    --new-pastel-green: #83be00;
    --new-pastel-red: #ff5c50;
    --new-pastel-purple: #8d59ff;
    --new-pastel-blue: #59b9ff;
    --new-neutral-light5: #e8e8e8;
    --new-neutral-light4: #b7b7b7;
    --new-neutral-light3: #959595;
    --new-neutral-light2: #646464;
    --new-neutral-light1: #464646;
    --new-neutral: #181818;
    --new-neutral-dark1: #161616;
    --new-neutral-dark2: #111111;
    --new-neutral-dark3: #0d0d0d;
    --new-neutral-dark4: #0a0a0a;
    --new-neutral-dark5: #0a0a0a;
}
