.spinner {
    //here we have --color,--size,--width,--height,...
    --tick: 0.1s;
    --duration: calc(12 * var(--tick));
    --rotation: calc(360deg / 12);
    color: var(--color);
    display: inline-block;
    position: relative;
    width: calc(var(--size) * 2);
    height: calc(var(--size) * 2);
    > div {
        transform-origin: var(--size) var(--size);
        animation: spinner var(--duration) linear infinite;
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: var(--size);
            width: var(--width);
            height: var(--height);
            border-radius: 20%;
            background: currentColor;
        }
        @for $i from 0 through 12 {
            &:nth-child(#{$i}) {
                transform: rotate(calc(#{$i} * var(--rotation)));
                animation-delay: calc((-1 * var(--duration)) - (calc(#{$i} * var(--tick))));
            }
        }
    }
}

@keyframes spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
