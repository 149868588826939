.inline {
    display: inline;
}
.block {
    display: block;
}
.inline-block {
    display: inline-block;
}
.flex {
    display: flex;
}
.inline-flex {
    display: inline-flex;
}
.grid {
    display: grid;
}
.inline-grid {
    display: inline-grid;
}
.table {
    display: table;
}
.inline-table {
    display: inline-table;
}
