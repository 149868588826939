.divide-x > * + * {
    border-width: 0;
    border-right-width: 0;
    border-left-width: 1px;
}
.divide-x-2 > * + * {
    border-width: 0;
    border-right-width: 0;
    border-left-width: 2px;
}
.divide-x-4 > * + * {
    border-width: 0;
    border-right-width: 0;
    border-left-width: 4px;
}
.divide-y > * + * {
    border-width: 0;
    border-top-width: 1px;
    border-bottom-width: 0;
}
.divide-y-2 > * + * {
    border-width: 0;
    border-top-width: 2px;
    border-bottom-width: 0;
}
.divide-y-4 > * + * {
    border-width: 0;
    border-top-width: 4px;
    border-bottom-width: 0;
}
.divide-solid > * + * {
    border-style: solid;
}
.divide-dotted > * + * {
    border-style: dotted;
}
.divide-dashed > * + * {
    border-style: dashed;
}
.divide-new-primary > * + * {
    border-color: var(--new-primary);
}
.divide-new-neutral-light5 > * + * {
    border-color: var(--new-neutral-light5);
}
.divide-new-neutral > * + * {
    border-color: var(--new-neutral);
}

//? Example for sx prop:
// <Stack p={0} gap={0}
//     sx={{
//         '& > * + *': {
//             '&.MuiButtonBase-root': {
//                 borderRadius: 0,borderStyle: 'solid',borderColor: 'newNeutral.light5'
//                 borderWidth: 0,borderTopWidth: 1,borderBottomWidth: 0,
//             }
//         }
//     }}
// > {Array.from({length:5}).map((_,i))=><div key={i} className="py-4" />} </Stack>
