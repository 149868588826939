@import url('https://fonts.googleapis.com/css2?family=Caladea&display=swap');
@import './colors.scss';
@import './spacing.scss';
@import './display.scss';
@import './shadows.scss';
@import './divider.scss';
@import './carousel.scss';
@import './browser.scss';
@import './others.scss';

html,
body {
    min-height: 100%;
    background-color: #f6f6f7 !important;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 10px;

        &:hover {
            background: #afafaf;
        }
    }
}

a[href] {
    text-decoration: none;
}

// tailwind classes
.capitalize {
    text-transform: capitalize;
}

.min-w-max {
    min-width: max-content !important;
}

.w-full {
    width: 100%;
}
.h-full {
    height: 100%;
}

.flex-1 {
    flex: 1 1 0%;
}
.items-center {
    align-items: center;
}
.ease-linear {
    transition-timing-function: linear !important;
}
.pointer-events-initial {
    pointer-events: initial !important;
}
.pointer-events-none {
    pointer-events: none !important;
}
.shrink-0 {
    flex-shrink: 0;
}
.grow-1 {
    flex-grow: 1;
}
.object-contain {
    object-fit: contain;
}
.object-cover {
    object-fit: cover;
}
.object-fill {
    object-fit: fill;
}
.object-none {
    object-fit: none;
}
.appearance-none {
    appearance: none !important;
}
button.no-style,
a.no-style {
    border: none;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
}
.break-all {
    word-break: break-all;
}

.menu-content {
    &::-webkit-scrollbar {
        height: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background: #afafaf;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.MuiDataGrid-virtualScroller,
.custom-scroll {
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 8px;

        &:hover {
            background: #afafaf;
        }
    }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    appearance: none;
}

.html-container {
    background-color: #f5f5f5;
    border-radius: 10px;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 10px;

        &:hover {
            background: #afafaf;
        }
    }

    * {
        margin: 0;
        background-color: transparent !important;
    }
}
