.ml-auto {
    margin-left: auto;
}
.mr-auto {
    margin-right: auto;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
@for $i from 0 through 10 {
    //* Generate padding classes:
    .p-#{$i} {
        padding: $i * 0.25rem;
    }
    .-p-#{$i} {
        padding: $i * -0.25rem;
    }
    .px-#{$i} {
        padding-right: $i * 0.25rem;
        padding-left: $i * 0.25rem;
    }
    .-px-#{$i} {
        padding-right: $i * -0.25rem;
        padding-left: $i * -0.25rem;
    }
    .py-#{$i} {
        padding-top: $i * 0.25rem;
        padding-bottom: $i * 0.25rem;
    }
    .-py-#{$i} {
        padding-top: $i * -0.25rem;
        padding-bottom: $i * -0.25rem;
    }
    .pt-#{$i} {
        padding-top: $i * 0.25rem;
    }
    .-pt-#{$i} {
        padding-top: $i * -0.25rem;
    }
    .pr-#{$i} {
        padding-right: $i * 0.25rem;
    }
    .-pr-#{$i} {
        padding-right: $i * -0.25rem;
    }
    .pb-#{$i} {
        padding-bottom: $i * 0.25rem;
    }
    .-pb-#{$i} {
        padding-bottom: $i * -0.25rem;
    }
    .pl-#{$i} {
        padding-left: $i * 0.25rem;
    }
    .-pl-#{$i} {
        padding-left: $i * -0.25rem;
    }
}
@for $i from 0 through 10 {
    //* Generate margin classes:
    .m-#{$i} {
        margin: $i * 0.25rem;
    }
    .-m-#{$i} {
        margin: $i * -0.25rem;
    }
    .mx-#{$i} {
        margin-right: $i * 0.25rem;
        margin-left: $i * 0.25rem;
    }
    .-mx-#{$i} {
        margin-right: $i * -0.25rem;
        margin-left: $i * -0.25rem;
    }
    .my-#{$i} {
        margin-top: $i * 0.25rem;
        margin-bottom: $i * 0.25rem;
    }
    .-my-#{$i} {
        margin-top: $i * -0.25rem;
        margin-bottom: $i * -0.25rem;
    }
    .mt-#{$i} {
        margin-top: $i * 0.25rem;
    }
    .-mt-#{$i} {
        margin-top: $i * -0.25rem;
    }
    .mr-#{$i} {
        margin-right: $i * 0.25rem;
    }
    .-mr-#{$i} {
        margin-right: $i * -0.25rem;
    }
    .mb-#{$i} {
        margin-bottom: $i * 0.25rem;
    }
    .-mb-#{$i} {
        margin-bottom: $i * -0.25rem;
    }
    .ml-#{$i} {
        margin-left: $i * 0.25rem;
    }
    .-ml-#{$i} {
        margin-left: $i * -0.25rem;
    }
}
@for $i from 0 through 10 {
    //* Generate spacing classes
    .space-x-#{$i} > * + * {
        margin-left: $i * 0.25rem;
    }
    .-space-x-#{$i} > * + * {
        margin-left: $i * -0.25rem;
    }
    .space-y-#{$i} > * + * {
        margin-top: $i * 0.25rem;
    }
    .-space-y-#{$i} > * + * {
        margin-top: $i * -0.25rem;
    }
}
