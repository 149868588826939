@for $i from 0 through 5 {
    .line-clamp-#{$i} {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: $i;
        text-overflow: ellipsis;
        white-space: normal;
    }
}
